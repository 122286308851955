<template>
    <div class="row dashboard pb-5">
        <div class="col-12">
            <div class="row g-4">
                <div v-if="activeUser.id && !activeUser.is_verified" class="col-lg-12">
                    <user-email-verification />                   
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import UserEmailVerification from "@/components/UserEmailVerification.vue"

export default {
    name: 'dashboard',
    components:{
        UserEmailVerification,
    },
    computed:{
        activeUser(){
            return this.$store.state.AppActiveUser
        },
    },
}
</script>

<style scoped>
</style>
